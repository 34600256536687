import { createRouter, createWebHistory } from 'vue-router'
import { isTokenValid, logout } from "../services/authService.js";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/HomePage.vue')
  },
  {
    path: '/elec',
    name: 'elec',
    component: () => import('../pages/ElecPage.vue')
  },
  {
    path: '/tp',
    name: 'tp',
    component: () => import('../pages/TpPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && localStorage.getItem('token') && !isTokenValid()) {
      logout();
      next({ name: 'home' });
  } else if (to.meta.requiresAuth && !localStorage.getItem('token')) {
      next({ name: 'home' });
  } else if (to.name === 'login' && localStorage.getItem('token')) {
      next({ name: 'dashboard' });
  } else if (to.name === 'register' && localStorage.getItem('token')) {
      next({ name: 'dashboard' });
  }
  else {
      next();
  }
});

export default router
